<template>
  <div>
    <FullWidthImageHero :height="'450px'" :mobileHeight="'300px'">
      <b-container class="h-100">
        <b-row align-h="start" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5">
                <h1 class="h1 font-weight-bolder mb-4">
                  Membership with Everything in One Place
                </h1>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                  >Get ScoreMaster<sup>&reg;</sup></SignupLinkButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container>
      <b-row align-h="center" class="mb-5">
        <b-col cols="12" md="10">
          <div
            v-if="memberPlans.signupPlans"
            class="bg-white text-text-black mt-5 box-shadow member-table"
            :class="{ 'two-col': !plan2 }"
          >
            <div class="d-none d-lg-grid bg-dark text-white col">
              <p class="h2 font-weight-bolder cell">
                Membership Benefits
              </p>
              <p class="h4 font-weight-normal cell">
                Smart Credit Report<sup>&reg;</sup> &amp; Scores
              </p>
              <p class="h4 font-weight-normal cell">
                ScoreTracker
              </p>
              <p class="h4 font-weight-normal cell">
                ScoreBuilder<sup>&reg;</sup>
              </p>
              <p class="h4 font-weight-normal cell">
                ScoreMaster<sup>&reg;</sup>
              </p>
              <p class="h4 font-weight-normal cell" v-if="hasActions">
                Actions
              </p>
              <p class="h4 font-weight-normal cell">
                Credit Monitoring
              </p>
              <p class="h4 font-weight-normal cell">
                Money Manager
              </p>
              <p class="h4 font-weight-normal cell">
                3B Report &amp; Scores
              </p>
              <p
                class="h4 font-weight-normal cell"
                v-if="isFraudInsuranceAvailable"
              >
                $1 Million ID Fraud Insurance
              </p>
            </div>
            <div class="col" v-if="plan1">
              <div class="cell">
                <p class="h1 font-weight-bolder mb-3">
                  ${{ plan1.chargeDisplay }}
                  <span class="font-weight-light">/mo</span>
                </p>
              </div>
              <div class="cell" data-label="Smart Credit Report® & Scores">
                <p v-if="plan1.premium" class="h5">Unlimited</p>
                <!-- prettier-ignore -->
                <p v-else class="h5">
                    {{ plan1.maxCreditReportsPerMonth }} /mo update<template v-if="plan1.maxCreditReportsPerMonth > 1">s</template>
                  </p>
              </div>
              <div class="cell" data-label="ScoreTracker">
                <p class="h5">Unlimited</p>
              </div>
              <div class="cell" data-label="ScoreBuilder™">
                <p v-if="plan1.hasScoreBuilder" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div class="cell" data-label="ScoreMaster®">
                <p v-if="plan1.hasScoreMaster" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div v-if="hasActions" class="cell" data-label="Actions">
                <p
                  v-if="!plan1.hasAction || plan1.maxActionsPerMonth === 0"
                  class="h5"
                >
                  --
                </p>
                <p v-else-if="plan1.premium" class="h5">Unlimited</p>
                <p v-else class="h5">{{ plan1.maxActionsPerMonth }} /mo</p>
              </div>
              <div class="cell" data-label="Credit Monitoring">
                <p v-if="plan1.hasCreditMonitoring" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div class="cell" data-label="Money Manager">
                <p v-if="plan1.hasMoneyManager" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div class="cell" data-label="3B Report & Scores">
                <!-- prettier-ignore -->
                <p v-if="plan1.included3BReportsPerMonth" class="h5">
                    {{ plan1.included3BReportsPerMonth }} /mo<template v-if="plan1.included3BReportsPerMonth > 1">s</template>
                  </p>
                <p v-else class="h5">A La Carte</p>
              </div>
              <div
                class="cell"
                data-label="$1 Milllion ID Fraud Insurance"
                v-if="isFraudInsuranceAvailable"
              >
                <p v-if="plan1.hasMoneyManager" class="h5">
                  Included*<br />Activation Required
                </p>
                <p v-else class="h5">--</p>
              </div>
            </div>
            <div class="col" v-if="plan2">
              <div class="cell">
                <p class="h2 font-weight-bolder mb-1 mb-lg-4 mt-2 mt-lg-0">
                  {{ plan2.name }}
                </p>
                <p class="h1 font-weight-bolder mb-3">
                  ${{ plan2.chargeDisplay }}
                  <span class="font-weight-light">/mo</span>
                </p>
              </div>
              <div class="cell" data-label="Smart Credit Report® & Scores">
                <p v-if="plan2.premium" class="h5">Unlimited</p>
                <!-- prettier-ignore -->
                <p v-else class="h5">
                    {{ plan2.maxCreditReportsPerMonth }} /mo update<template v-if="plan2.maxCreditReportsPerMonth > 1">s</template>
                  </p>
              </div>
              <div class="cell" data-label="ScoreTracker">
                <p class="h5">Unlimited</p>
              </div>
              <div class="cell" data-label="ScoreBuilder™">
                <p v-if="plan2.hasScoreBuilder" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div class="cell" data-label="ScoreMaster®">
                <p v-if="plan2.hasScoreMaster" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div v-if="hasActions" class="cell" data-label="Actions">
                <p
                  v-if="!plan2.hasAction || plan2.maxActionsPerMonth === 0"
                  class="h5"
                >
                  --
                </p>
                <p v-else-if="plan2.premium" class="h5">Unlimited</p>
                <p v-else class="h5">{{ plan2.maxActionsPerMonth }} /mo</p>
              </div>
              <div class="cell" data-label="Credit Monitoring">
                <p v-if="plan2.hasCreditMonitoring" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div class="cell" data-label="Money Manager">
                <p v-if="plan2.hasMoneyManager" class="h5">Unlimited</p>
                <p v-else class="h5">--</p>
              </div>
              <div class="cell" data-label="3B Report & Scores">
                <!-- prettier-ignore -->
                <p v-if="plan2.included3BReportsPerMonth" class="h5">
                    {{ plan2.included3BReportsPerMonth }} /mo<template v-if="plan2.included3BReportsPerMonth > 1">s</template>
                  </p>
                <p v-else class="h5">A La Carte</p>
              </div>
              <div
                class="cell"
                data-label="$1 Million ID Fraud Insurance"
                v-if="isFraudInsuranceAvailable"
              >
                <p v-if="plan2.hasMoneyManager" class="h5">
                  Included*<br />Activation Required
                </p>
                <p v-else class="h5">--</p>
              </div>
            </div>
          </div>
          <div v-else class="text-center my-5">
            <Loader class="mt-5" />
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="9" class="text-center">
          <p class="mb-5 text-left">
            <small>
              <em
                >*Identity Fraud Expense Reimbursement is provided under a
                Blanket Policy issued to Consumer Direct, Inc. by Voyager
                Indemnity Insurance Company, an Assurant Specialty Property
                company.</em
              >
            </small>
          </p>
          <SignupLinkButton
            :to="{ name: 'IndividualSignup' }"
            pill
            variant="secondary"
            class="w-100 mt-4 text-white font-weight-bold"
            >Sign Up Now!</SignupLinkButton
          >
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Membership',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  data() {
    return {
      isFraudInsuranceAvailable: false,
      plan1: null,
      plan2: null,
      hasActions: false,
    }
  },
  computed: {
    memberPlans: function() {
      return this.$store.getters['member/getMemberPlans']
    },
  },
  watch: {
    memberPlans() {
      this.setPlans()
    },
  },
  mounted() {
    if (this.memberPlans.signupPlans) this.setPlans()
  },
  methods: {
    setPlans() {
      let sponsoredPlan = this.memberPlans.signupPlans.find(
        x => x.planType === 'SPONSORED'
      )
      if (sponsoredPlan) {
        this.plan1 = sponsoredPlan
        this.hasActions = this.plan1.hasAction
        this.isFraudInsuranceAvailable = this.plan1.hasIdFraudInsurance
      } else {
        this.plan1 = this.memberPlans.signupPlans[0]
        this.plan2 = this.memberPlans.signupPlans[1]
        this.hasActions = this.plan1.hasAction || this.plan2.hasAction
        this.isFraudInsuranceAvailable =
          this.plan1.hasIdFraudInsurance || this.plan2.hasIdFraudInsurance
      }

      document.dispatchEvent(new Event('x-app-rendered'))
    },
  },
}
</script>
