<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/features_hero.jpg')"
      :mobileImage="require('@/assets/images/features_hero_mobile.jpg')"
      :height="'450px'"
      :mobileHeight="'450px'"
    >
      <b-container class="h-100">
        <b-row align-h="start" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <h1 class="h1 font-weight-bolder mb-4">
                  Features Included with Membership
                </h1>
                <p class="h4 mb-3">
                  ScoreMaster<sup>&reg;</sup> has your money, credit and
                  identity in one place with Action buttons and score tools.
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                  >Sign Up Now!</SignupLinkButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <AlternatingFeatures />
  </div>
</template>

<script>
import AlternatingFeatures from '@/components/common/AlternatingFeatures'

export default {
  name: 'Features',
  components: {
    AlternatingFeatures,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted() {
    document.dispatchEvent(new Event('x-app-rendered'))
  },
}
</script>
