<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/home_hero.jpg')"
      :mobileImage="require('@/assets/images/home_hero_mobile.jpg')"
      :mobileHeight="'100%'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col
            cols="12"
            md="8"
            offset-md="4"
            lg="6"
            offset-lg="5"
            xl="5"
            offset-xl="6"
          >
            <b-row>
              <b-col class="hero-text p-5">
                <h1 class="h1 font-weight-bolder mb-4 mt-2">
                  ScoreMaster<sup>&reg;</sup> Sitemap
                </h1>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="pt-5">
      <ul>
        <li v-for="page in sitemap" :key="page.page">
          <a
            :target="page.url.startsWith('http') ? '_blank' : '_self'"
            :href="page.url"
            >{{ page.page }}</a
          >
          <ul :v-if="page.childPages">
            <li v-for="childPage in page.childPages" :key="childPage.page">
              <a :href="childPage.url">{{ childPage.page }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Sitemap',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  data() {
    return {
      sitemap: [
        {
          page: 'Homepage',
          url: '/',
        },
        {
          page: 'How It Works',
          url: '/how-it-works',
        },
        {
          page: 'Benefits For Consumers',
          url: '/consumer-credit-report-benefits',
          childPages: [
            {
              page: 'Consumer Signups',
              url: '/consumer-signups',
            },
          ],
        },
        {
          page: 'Benefits For Lenders',
          url: '/lender-benefits',
          childPages: [
            {
              page: 'Lender Signups',
              url: '/lender-signup',
            },
          ],
        },
        {
          page: 'Benefits For Employees',
          url: '/employee-benefits',
          childPages: [
            {
              page: 'Employee Signup',
              url: '/employer-signup',
            },
          ],
        },
        {
          page: 'Features',
          url: '/features',
        },
        {
          page: 'Blog',
          url: '/blog',
          childPages: [
            {
              page: 'Borrowing Tips',
              url: '/blog/category/5/borrowing-tips',
            },
            {
              page: 'Lending Tips',
              url: '/blog/category/6/lending-tips',
            },
            {
              page: 'Workplace Tips',
              url: '/blog/category/7/workplace-tips',
            },
            {
              page: 'Uncategorized',
              url: '/blog/category/1/uncategorized',
            },
          ],
        },
        {
          page: 'About Us',
          url: 'https://www.consumerdirect.com/about.html',
        },
        {
          page: 'Contact Us',
          url: 'https://member.scoremaster.com/help/contact-us/',
        },
        {
          page: 'Service Agreement',
          url:
            'https://member.scoremaster.com/help/terms-and-privacy/service-agreement.htm',
        },
        {
          page: 'Terms of Use',
          url:
            'https://member.scoremaster.com/help/terms-and-privacy/site-use.htm',
        },
        {
          page: 'Privacy Policy',
          url:
            'https://member.scoremaster.com/help/terms-and-privacy/privacy-policy.htm',
        },
        {
          page: 'Security',
          url: 'https://member.scoremaster.com/help/resources/security.htm',
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
<style lang="scss" scoped>
@media (min-width: 961px) {
  .hero-text {
    padding: 1.5rem 115px;
  }
}
</style>
