<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/thankyou_hero.jpg')"
      :mobileImage="require('@/assets/images/thankyou_hero_mobile.jpg')"
      :mobileHeight="'800px'"
      :height="'820px'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" lg="7" offset-lg="1" class="bg-white hero-text">
            <p class="h1 font-weight-bolder mb-5 mt-3">
              Thank you!
            </p>
            <p class="mb-5 pb-5">
              A member of our dedicated partnership team will be in
              <br class="d-none d-lg-block" />touch with you shortly.
            </p>
            <b-button
              to="/"
              pill
              size="lg"
              variant="secondary"
              class="w-100 font-weight-bold mt-5 mx-auto mb-5 d-block"
            >
              Return to Homepage
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
<style lang="scss" scoped>
@media (min-width: 961px) {
  .hero-text {
    padding: 1.5rem 115px;
  }
}
</style>
