<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/about_us_hero.jpg')"
      :mobileImage="require('@/assets/images/about_us_hero_mobile.jpg')"
      :mobileHeight="'100%'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col
            cols="12"
            md="8"
            offset-md="4"
            lg="6"
            offset-lg="5"
            xl="5"
            offset-xl="6"
          >
            <b-row>
              <b-col class="hero-text p-5">
                <h1 class="h1 font-weight-bolder mb-4 mt-2">
                  What We Do Best
                </h1>
                <p class="h4 mb-3">
                  We empower ownership of your credit score and better use of
                  your money, credit & privacy.
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                >
                  Get ScoreMaster<sup>&reg;</sup>
                </SignupLinkButton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>

    <b-container class="py-3">
      <b-row class="mt-5">
        <b-col>
          <h2 class="h1 font-weight-bolder mb-5 text-center text-md-left">
            About ScoreMaster<sup>&reg;</sup>
          </h2>
          <p>
            Few things impact your life more than your credit score.
          </p>
          <p>
            And you may not know this, but there was a time when people couldn’t
            do anything to change their score. Now with our exclusive set of
            tools, you can achieve your best possible score within days.
          </p>
          <p>
            Plus, with our $1 Million Whole Family Fraud Insurance and credit
            monitoring, you can even protect your score.
          </p>
          <p>
            We also help realtors, lenders and brokers get their customers
            better loans and more people qualified.
          </p>
          <p class="mb-6">
            Employers use ScoreMaster<sup>&reg;</sup> to help their employees
            get their best credit score and be better users of their money,
            credit and privacy, so they can perform at their highest level.
          </p>
          <b-img
            fluid
            src="@/assets/images/60k_more_bank_logos.png"
            alt="60k plus partnered financial institutions"
            class="d-none d-md-block mb-6 mx-auto"
          />
          <b-img
            fluid
            center
            src="@/assets/images/60k_more_bank_logos_mobile.png"
            alt="60k plus partnered financial institutions"
            class="d-md-none mb-5"
          />
          <h2 class="h1 font-weight-bolder mb-5">
            We’re Connected with the Best
          </h2>
          <p>
            We’ve partnered with over 60,000 financial institutions, banks, and
            lenders to make it easier to manage your money and credit, no matter
            who you bank with or borrow from.
          </p>
          <p>
            ScoreMaster<sup>&reg;</sup>: The fastest way to your best credit
            score and so much more.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'About Us',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>

<style lang="scss" scoped></style>
