<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/notfound_hero.jpg')"
      :mobileImage="require('@/assets/images/notfound_hero_mobile.jpg')"
      :mobileHeight="'405px'"
      :height="'705px'"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col
            cols="12"
            lg="5"
            offset-lg="1"
            class="bg-white py-4 px-5 hero-text"
          >
            <p class="h1 font-weight-bolder mb-5 mx-md-5 mt-3">
              Page Not Found
            </p>
            <p class="mb-5 mx-md-5 mt-3 pr-md-5">
              We're sorry, the page you are looking for cannot be found at this
              time.
            </p>
            <b-button
              to="/"
              pill
              size="lg"
              variant="secondary"
              class="d-block text-center mb-5 mx-md-5"
            >
              <span class="h6 font-weight-bold">Return to Homepage</span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: 'https://www.scoremaster.com/404/',
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
