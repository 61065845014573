<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/howitworks_hero.jpg')"
      :mobileImage="require('@/assets/images/howitworks_hero_mobile.jpg')"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="h1 font-weight-bolder mb-4">
                  ScoreMaster<sup>&reg;</sup> is built to help people.
                </p>
                <p class="h4">
                  Intuitive design with a focus on understanding and
                  empowerment.
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                  >Sign Up Now!</SignupLinkButton
                >
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="py-3">
      <FunFastSimpleSlider />
      <b-row align-h="center" class="pt-5 text-center mt-5">
        <b-col cols="12">
          <p class="h1 font-weight-bolder">
            More Features to Get &amp;
            <br class="d-none d-md-block d-lg-none" />Keep Your Best Score
          </p>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-5 text-center">
        <b-col cols="6" lg="2" class="mb-5">
          <div class="svg-wrap">
            <IconScoreBuilder style="height:60px;" class="icon icon-primary" />
          </div>
          <p class="h5 font-weight-bold">Score<br />Builder<sup>&reg;</sup></p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <div class="svg-wrap">
            <IconScoreTracker style="width:62px;" class="icon icon-primary" />
          </div>
          <p class="h5 font-weight-bold">Score<br />Tracker</p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <div class="svg-wrap">
            <IconMoneyManager style="width:60px;" class="icon icon-primary" />
          </div>
          <p class="h5 font-weight-bold">Money <br />Manager</p>
        </b-col>
        <b-col cols="6" lg="2" class="mb-5">
          <div class="svg-wrap">
            <IconInsurance style="height:60px;" class="icon icon-primary" />
          </div>
          <p class="h5 font-weight-bold">$1 Million Fraud <br />Insurance</p>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12" lg="4" class="text-center">
          <b-button
            to="/features/"
            pill
            size="lg"
            variant="secondary"
            class="w-100 text-center"
          >
            See All Features
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import IconScoreBuilder from '@/assets/svg/icon_SB.svg'
import IconScoreTracker from '@/assets/svg/icon_ST.svg'
import IconMoneyManager from '@/assets/svg/icon_MM.svg'
import IconInsurance from '@/assets/svg/icon_FI.svg'
import FunFastSimpleSlider from '@/components/common/FunFastSimpleSlider'

export default {
  name: 'MoreFeatures',
  components: {
    IconScoreBuilder,
    IconScoreTracker,
    IconMoneyManager,
    IconInsurance,
    FunFastSimpleSlider,
  },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
}
</script>
